import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CampaignsService } from '../../../../services/campaigns.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../../../store/auth/authentication.service';
import * as selectorsAuth from '../../../../store/auth/authentication.selectors';
import { Store } from '@ngrx/store';
import { State } from '../../../../store';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalExtendCampaignComponent } from '../brief-campaign/modals/modal-extend-campaign/modal-extend-campaign.component';
import { ModalViewCodeConversionsLinksComponent } from '../brief-campaign/modals/modal-view-code-conversions-links/modal-view-code-conversions-links.component';
import { openPlansDialog } from '../../../../store/app/app.actions';
import { environment } from 'src/environments/environment';
import { AmplifyService } from 'aws-amplify-angular';

@Component({
  selector: 'app-new-campaign',
  templateUrl: './new-campaign.component.html',
  styleUrls: ['./new-campaign.component.scss']
})
export class NewCampaignComponent implements OnInit, OnDestroy {

  new_campaign_form: FormGroup;

  campaignIdSelectedForMultiple = '';

  loading = false;

  currencies = [];
  private subscriptions: Subscription[] = [];

  uploading_brand_logo = false;
  is_share = false;
  showEffect = false;

  start_date_under_one_week_from_today = false;
  start_date_before_today = false;
  campaign_extended = false;

  has_report = false;

  deployedLinks = false;
  deployedHashtags = false;

  removable = true;
  selectable = true;

  chips = {
    'hashtags': [],
    'mentions': [],
  }

  destinationUrlAdded = false;
  cpaLinks = [];

  isInputCpaLinkFocused: boolean = false;
  isInputDestinationLinkFocused: boolean = false;


  constructor(
    private _campaignsService: CampaignsService,
    public dialogRef: MatDialogRef<NewCampaignComponent>,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<State>,
    private _authService: AuthenticationService,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private _amplifyService: AmplifyService,

  ) {
    this.new_campaign_form = new FormGroup({
      'campaign_name': new FormControl('', [
        Validators.required,
        Validators.maxLength(25)
      ]),
      'custom:currency_type': new FormControl('USD', [
        Validators.required,
      ]),
      cpaLinks: new FormControl('', [Validators.required, Validators.pattern(/^(https?:\/\/)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(\/.*)?$/)]),
      destinationUrl: new FormControl('', [Validators.required, Validators.pattern(/^(https?:\/\/)?([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(\/.*)?$/)]),
      start_date: new FormControl(''),
      end_date: new FormControl(''),
      selectedChip: new FormControl('hashtags'),
      valueChip: new FormControl(''),
    });
  }

  ngOnInit() {
    this.loading = true;
    if (this.data.createCampaign) {
      this.new_campaign_form.controls.start_date.setValue(new Date());
      this.new_campaign_form.controls.end_date.setValue(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000));
      this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
        this.new_campaign_form.controls['custom:currency_type'].setValue(user.currency);
      })
    } else {
      if (this.data.campaignName) {
        this.new_campaign_form.controls['campaign_name'].setValue(this.data.campaignName);
      }
      if (this.data.startDate) {
        this.new_campaign_form.controls['start_date'].setValue(new Date(this.data.startDate * 1000));
      }
      if (this.data.endDate) {
        this.new_campaign_form.controls['end_date'].setValue(new Date(this.data.endDate * 1000));
      }
      if (this.data.hashtag_list) {
        this.chips.hashtags = this.data.hashtag_list
      }
      if (this.data.mentions_compulsory_elements) {
        this.chips.mentions = this.data.mentions_compulsory_elements
      }
      if (this.data.cpa_link) {
        this.cpaLinks = this.data.cpa_link;
      }
      if (this.data.destination_url) {
        this.new_campaign_form.controls['destinationUrl'].setValue(this.data.destination_url);
        this.destinationUrlAdded = true;
      }
      if (this.data.has_report) {
        this.new_campaign_form.controls['start_date'].disable(); // Deshabilita completamente el input
      }
      this.new_campaign_form.controls['custom:currency_type'].disable();
      this.new_campaign_form.controls['custom:currency_type'].setValue(this.data.currency_code);

    }

    this.subscriptions.push(
      this._authService.getPublicCurrencies().subscribe(response => {
        this.currencies = response;
        this.loading = false;
      }, () => {
      })
    );
    const campaignFlowMap = {
      complete_flow: 'campaigns.newCampaignModal.completeFlow',
      management_report: 'campaigns.newCampaignModal.managementAndReport',
      report_only: 'campaigns.newCampaignModal.report'
    };
    
    if (this.data.campaign_type) {
      this.data.flowSelectedForBack = this.data.campaign_type;
    }
    this.data.flowSelected = this.translate.instant(campaignFlowMap[this.data.flowSelectedForBack] || '');

    this.start_date_under_one_week_from_today =
    new Date(this.new_campaign_form.controls['start_date'].value).getTime() <
    new Date().getTime() + 60 * 60 * 24 * 1000 * 5;
  this.start_date_before_today =
    new Date(this.new_campaign_form.controls['start_date'].value).getTime() <
    new Date().getTime() - 60 * 60 * 24 * 1000;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  createCampaign() {
    this.loading = true;
    // Verificar los valores y construir los parámetros
    const hashtag_list = this.chips.hashtags && this.chips.hashtags.length > 0 ? this.chips.hashtags : null;
    const mentions_compulsory_elements = this.chips.mentions && this.chips.mentions.length > 0 ? this.chips.mentions : null;
    const destination_url = this.new_campaign_form.controls['destinationUrl'].value || null;
    const cpa_link = this.cpaLinks && this.cpaLinks.length > 0 ? this.cpaLinks : null;
    const brand_logo = this.data.brandLogo || null;

    // Construir un objeto con los parámetros opcionales
    const optionalParams = {
      hashtag_list,
      mentions_compulsory_elements,
      destination_url,
      cpa_link,
      brand_logo
    };

    // Eliminar los parámetros que sean null o undefined
    Object.keys(optionalParams).forEach(key => {
      if (optionalParams[key] === null || optionalParams[key] === undefined) {
        delete optionalParams[key];
      }
    });

    // Usar los parámetros verificados en el método createCampaign
    this._campaignsService.createCampaign(
      this.new_campaign_form.controls['campaign_name'].value,
      this.new_campaign_form.controls['custom:currency_type'].value,
      this.new_campaign_form.controls['start_date'].value,
      this.new_campaign_form.controls['end_date'].value,
      this.data.flowSelectedForBack,
      optionalParams // Pasar los parámetros filtrados
    ).subscribe(
      result => {
        if (result['status'] === 201) {
          if (this.data.influencers) {
            this.addInfluencerToCampaign(result.body.campaign_id, this.data.influencers);
          } else {
            this.openCustomSuccessSnackbar(this.translate.instant('campaigns.newCampaignModal.campaignCreatedSuccessfully'), result.body.campaign_id);
            const result_dialog = {
              status: 'added',
              refresh: result['body']['refresh_token']
            };
            this.dialogRef.close(result_dialog);
            this.router.navigate(['/app/campaign/' + result.body.campaign_id]);
          }
        } else {
          this.dialogRef.close();
          this.openCustomErrorSnackbar(this.translate.instant('customSnackbar.thereHasBeenError'));
        }
      },
      error => {
        this.dialogRef.close();
        if (error.error.message) {
          this.openCustomErrorSnackbar(error.error.message);
        } else {
          this.openCustomErrorSnackbar(this.translate.instant('customSnackbar.thereHasBeenError'));
        }
      }
    );

  }

  openCustomSuccessSnackbar(message, campaign_id) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: message, type: 'success', campaign_id: campaign_id },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'success-snackbar',
    });
  }

  openCustomErrorSnackbar(message) {
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: { message: message, type: 'error' },
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: 'error-snackbar',
    });
  }

  addInfluencerToCampaign(campaign_id, influencers) {
    this._campaignsService.addInfluencerToCampaign(campaign_id, influencers).subscribe(result => {
      this.openCustomSuccessSnackbar(result.body.message, campaign_id);
      const result_dialog = {
        status: 'added',
        refresh: result['body']['refresh_token']
      };
      this.dialogRef.close(result_dialog);
    }, error => {
      if (error.error.message) {
        this.openCustomErrorSnackbar(error.error.message);
      } else {
        this.openCustomErrorSnackbar(this.translate.instant('customSnackbar.thereHasBeenError'));
      }
    });
  }

  viewCampaign(campaign_id) {
    this.router.navigate(['/app/campaign', campaign_id]);
    this.dialogRef.close();
  }


  viewStartDate(isStartDate): void {
    if (this.data.has_report && !isStartDate) {
      this.extendCampaign();
    } else {
      this.start_date_under_one_week_from_today =
      new Date(this.new_campaign_form.controls['start_date'].value).getTime() <
      new Date().getTime() + 60 * 60 * 24 * 1000 * 5;
    this.start_date_before_today =
      new Date(this.new_campaign_form.controls['start_date'].value).getTime() <
      new Date().getTime() - 60 * 60 * 24 * 1000;

    if (
      new Date(this.new_campaign_form.controls['start_date'].value).getTime() >
      new Date(this.new_campaign_form.controls['end_date'].value).getTime()
    ) {
      this.new_campaign_form.controls['end_date'].setValue(
        this.new_campaign_form.controls['start_date'].value
      );
    }
    }
  }

  extendCampaign() {
    const dialogRef = this.dialog.open(ModalExtendCampaignComponent, {
      width: '410px',
      height: '310px',
      autoFocus: false,
      data: { last_end_date: this.data.endDate, new_end_date: this.new_campaign_form.controls['end_date'].value }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'success') {
        this.new_campaign_form.controls['end_date'].setValue(new Date(this.data.endDate * 1000));
      }
    });
  }

  restrictNonDateInput(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', '/', 'Delete']; // Teclas permitidas
    const key = event.key;

    // Permite solo números y las teclas adicionales definidas
    if (!/^\d$/.test(key) && !allowedKeys.includes(key)) {
      event.preventDefault(); // Evita la entrada de caracteres no válidos
    }
  }

  validatePartialDateInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    // Valida que el valor parcial sea numérico o siga el formato correcto
    if (!/^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/.test(value)) {
      input.value = value.slice(0, -1); // Elimina el último carácter no válido
    }
  }

  uploadBrandLogo(fileInput: any): void {
    this.uploading_brand_logo = true;

    const file = fileInput.target.files[0];

    if (file.size <= 5242880) {
      if (this.isImageTypeValid(file)) {
        const extension = this.getExtensionFromImage(file.type);

        let uploadTo = '';
        if (this.data.campaign_id) {
          uploadTo = this.data.campaign_id;
        } else {
          uploadTo = 'tmp';
        }

        this.uploadToS3(file, extension, uploadTo)
          .then(url => {
            this.data.brandLogo = url
            this.uploading_brand_logo = false;
          })
          .catch(() => {
            this.snackBar.openFromComponent(CustomSnackbarComponent, {
              data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
              duration: 3000,
              horizontalPosition: 'right',
              verticalPosition: 'top',
              panelClass: 'error-snackbar',
            });
            this.uploading_brand_logo = false;
          });
      } else {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
          data: { message: this.translate.instant('campaigns.newCampaignModal.unsupportedFileType'), type: 'error' },
          duration: 3000,
          horizontalPosition: 'right',
          verticalPosition: 'top',
          panelClass: 'error-snackbar',
        });
        this.uploading_brand_logo = false;
      }
    } else {
      this.snackBar.openFromComponent(CustomSnackbarComponent, {
        data: { message: this.translate.instant('campaigns.newCampaignModal.theFileExceeds'), type: 'error' },
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: 'error-snackbar',
      });
      this.uploading_brand_logo = false;
    }
  }


  uploadToS3(file: File, extension: string, uploadTo): Promise<string> {
    const config = {
      bucket: environment.aws_resources.s3.buckets.company_logo,
      region: environment.aws_resources.region,
      level: 'public',
      customPrefix: {
        public: '',
      },
    };

    // Generar un ID aleatorio
    const randomId = Math.random().toString(36).substring(2, 15) + Date.now().toString();

    return this._amplifyService.storage().vault.put(`${uploadTo}/` + randomId + extension, file, config)
      .then(() => 'https://s3-eu-west-1.amazonaws.com/' + config.bucket + `/${uploadTo}/` + randomId + extension);
  }


  getExtensionFromImage(file_type: string): string {
    return file_type.replace('image/', '.');
  }

  isImageTypeValid(file: File): boolean {
    return ['image/png', 'image/jpeg', 'image/jpg'].includes(file.type);
  }


  clearBrandLogo() {
    this.data.brandLogo = undefined;
  }

  addChips(): void {
    // Obtener el valor actual del campo de entrada
    const inputValue = this.new_campaign_form.controls['valueChip'].value || '';

    // Dividir el texto por espacios y filtrar entradas vacías
    const tags = inputValue.split(' ').filter(tag => tag.trim() !== '');

    // Determinar el tipo seleccionado para añadir las chips
    const typeChip = this.new_campaign_form.controls['selectedChip'].value;

    // Añadir cada tag al tipo correspondiente si no está vacío
    tags.forEach(tag => {
      if (!this.chips[typeChip].includes(tag)) {
        this.chips[typeChip].push(tag);
      }
    });

    // Limpiar el campo de entrada
    this.new_campaign_form.controls['valueChip'].setValue('');
  }


  removeHashtagsChips(chip: any, typeChip): void {
    const index = this.chips[typeChip].indexOf(chip);
    if (index >= 0) {
      this.chips[typeChip].splice(index, 1);
    }
  }

  transformInput(event: Event): void {
    // Obtener el valor actual del input
    const inputValue = (event.target as HTMLInputElement).value;
  
    // Si el campo está vacío, permitir que quede vacío
    if (!inputValue.trim()) {
      this.new_campaign_form.controls['valueChip'].setValue('', { emitEvent: false });
      return;
    }
  
    // Detectar el primer carácter
    const firstChar = inputValue.trim().charAt(0);
    if (firstChar === '#') {
      this.new_campaign_form.controls['selectedChip'].setValue('hashtags', { emitEvent: false });
    } else if (firstChar === '@') {
      this.new_campaign_form.controls['selectedChip'].setValue('mentions', { emitEvent: false });
    }
  
    // Determinar el prefijo según el tipo seleccionado
    const selectedChip = this.new_campaign_form.controls['selectedChip'].value;
    const prefix = selectedChip === 'hashtags' ? '#' : selectedChip === 'mentions' ? '@' : '';
  
    // Dividir en palabras por espacio
    const tags = inputValue.split(' ');
  
    // Transformar solo si la palabra no empieza con el prefijo
    const transformed = tags.map((tag) => {
      // Si la palabra no tiene contenido, devolverla tal cual (para manejar múltiples espacios)
      if (!tag.trim()) return tag;
  
      // Si la palabra no empieza con el prefijo, agregarlo
      return tag.startsWith(prefix) ? tag : `${prefix}${tag}`;
    });
  
    // Actualizar el valor del input con las palabras transformadas
    this.new_campaign_form.controls['valueChip'].setValue(transformed.join(' '), { emitEvent: false });
  }
  


  /**
 * Abrir modal que muestra la info que tienen que copiar
 * conversion links en formato <script>
 */
  viewCodeConversionLinks() {
    this.dialog.open(ModalViewCodeConversionsLinksComponent, {
      width: '500px',
      height: 'auto',
      autoFocus: false,
      data: {
        conversions: this.cpaLinks
      }
    });
  }

  addCpaLinks() {
    this.cpaLinks.push(this.new_campaign_form.controls['cpaLinks'].value);
    this.new_campaign_form.controls['cpaLinks'].setValue('');
    this.isInputCpaLinkFocused = false;
  }


  removeCpaLink(link) {
    const index = this.cpaLinks.indexOf(link);
    if (index >= 0) {
      this.cpaLinks.splice(index, 1);
    }
  }

  removeDestinationUrl() {
    this.new_campaign_form.controls['destinationUrl'].setValue('');
    this.destinationUrlAdded = false;
    this.isInputDestinationLinkFocused = false;
  }

  onFocus(typeLink: string) {
    const focusedProperty = typeLink === 'cpaLink' ? 'isInputCpaLinkFocused' : 'isInputDestinationLinkFocused';
    this[focusedProperty] = true;
  }

  onBlur(typeLink: string) {
    const controlName = typeLink === 'cpaLink' ? 'cpaLinks' : 'destinationUrl';
    const focusedProperty = typeLink === 'cpaLink' ? 'isInputCpaLinkFocused' : 'isInputDestinationLinkFocused';

    const control = this.new_campaign_form.get(controlName);

    // Verificar explícitamente si el valor es nulo o una cadena vacía
    if (control && (control.value === null || control.value === '')) {
      this[focusedProperty] = false;
    }
  }

  openModalPlans() {
    this.store.dispatch(openPlansDialog());
  }


  updateCampaign() {
    this.loading = true;
    // Verificar los valores y construir los parámetros
    const hashtag_list = this.chips.hashtags && this.chips.hashtags.length > 0 ? this.chips.hashtags : null;
    const mentions_compulsory_elements = this.chips.mentions && this.chips.mentions.length > 0 ? this.chips.mentions : null;
    const destination_url = this.new_campaign_form.controls['destinationUrl'].value || null;
    const cpa_link = this.cpaLinks && this.cpaLinks.length > 0 ? this.cpaLinks : null;
    const brand_logo = this.data.brandLogo || null;
    const campaign_name = this.new_campaign_form.controls['campaign_name'].value;
    // Convertir las fechas a timestamp en segundos
    const date_start = Math.floor(new Date(this.new_campaign_form.controls['start_date'].value).getTime() / 1000);
    const date_end = Math.floor(new Date( this.new_campaign_form.controls['end_date'].value).getTime() / 1000);

    // Construir un objeto con los parámetros opcionales
    const campaign_config = {
      hashtag_list,
      mentions_compulsory_elements,
      destination_url,
      cpa_link,
      brand_logo,
      campaign_name,
      date_start,
      date_end,
    };


    // Eliminar los parámetros que sean null o undefined
    Object.keys(campaign_config).forEach(key => {
      if (campaign_config[key] === null || campaign_config[key] === undefined) {
        delete campaign_config[key];
      }
    });
    const config = {
      campaign_config
    };
    this.subscriptions.push(
      this._campaignsService.updateCreateCampaignModal(this.data.campaign_id, config).subscribe(
        () => {
          this.loading = false;
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: this.translate.instant('campaigns.newCampaignModal.updateSuccess'), type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
          this.dialogRef.close('success');
        },
        () => {
          this.loading = false;
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
        }
      )
    );
  }


  onCloseDialog() {
    if (this.data.createCampaign) {
      this.dialogRef.close("back");
    }
  }

}


