import { Component, Inject, OnInit } from '@angular/core';
import { BriefService } from '../../../../services/brief.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { StateConfirmSendInvitationModalComponent } from '../state-confirm-send-invitation-modal/state-confirm-send-invitation-modal.component';

@Component({
  selector: 'app-confirm-send-invitation-modal',
  templateUrl: './confirm-send-invitation-modal.component.html',
  styleUrls: ['./confirm-send-invitation-modal.component.scss']
})
export class ConfirmSendInvitationModalComponent implements OnInit {
  campaign_id = '';
  campaignNameUppercase = '';

  loading: boolean;

  constructor(private _briefService: BriefService,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loading = false;
    this.campaign_id = this.data.campaign_id;
  }

  ngOnInit() {
    if (this.data.campaignName) {
      this.campaignNameUppercase = this.data.campaignName.toUpperCase();
    }
  }

  sendInvitation() {
    this.loading = true;
    this._briefService.getBriefInvitation(this.campaign_id).subscribe(response => {
      this.dialog.closeAll();
      this.openDialogStateConfirmSendInvitation(response);
    }, error => {
      this.dialog.closeAll();
      this.openDialogStateConfirmSendInvitation(error);
    });
  }

  openDialogStateConfirmSendInvitation(response_invitation): void {
    const dialogRef = this.dialog.open(StateConfirmSendInvitationModalComponent, {
      width: '600px',
      height: '310px',
      autoFocus: false,
      data: {invitation: response_invitation, campaign_id: this.campaign_id}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
