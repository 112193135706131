import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../store';
import { selectAppViewPort } from '../../../../../store/app/app.selectors';
import { NewCampaignComponent } from 'src/app/components/private/campaigns/new-campaign/new-campaign.component';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-private-stepper',
  templateUrl: './private-stepper.component.html',
  styleUrls: ['./private-stepper.component.scss']
})
export class PrivateStepperComponent implements OnInit {

  @Input() has_campaign: boolean;
  @Input() has_brief: boolean;
  @Input() has_progress: boolean;
  @Input() has_report: boolean;
  @Input() campaign_id: string;
  @Input() component_selected: string;

  @Input() campaignName: string;
  @Input() startDate;
  @Input() endDate;
  @Input() brandLogo: string;
  @Input() hashtag_list;
  @Input() mentions_compulsory_elements;
  @Input() cpa_link;
  @Input() destination_url;
  @Input() campaign_type;
  @Input() currency_code;

  @Input() planNameShown;
  @Input() haveInfluencerAdded;

  @Output() callCreateBrief = new EventEmitter<any>();
  @Output() callCreateReport = new EventEmitter<any>();
  @Output() callCreateProgress = new EventEmitter<any>();
  @Output() callGetComponent = new EventEmitter<any>();

  viewport: string;


  constructor(
    private dialog: MatDialog,
    private store: Store<State>,
  ) { }

  ngOnInit() {
    console.log('tnemos influencer added?', this.haveInfluencerAdded);
    this.store.select(selectAppViewPort).subscribe(result => {
      this.viewport = result;
    });
  }

  openModalCampaignConfiguration() {
    const dialogRef = this.dialog.open(NewCampaignComponent, {
      width: '625px',
      height: '625px',
      data: {
        createCampaign: false,
        campaignName: this.campaignName,
        brandLogo: this.brandLogo,
        startDate: this.startDate,
        endDate: this.endDate,
        hashtag_list: this.hashtag_list,
        mentions_compulsory_elements: this.mentions_compulsory_elements,
        cpa_link: this.cpa_link,
        destination_url: this.destination_url,
        planNameShown: this.planNameShown,
        campaign_id: this.campaign_id,
        has_report: this.has_report,
        campaign_type: this.campaign_type,
        currency_code: this.currency_code
      },
      panelClass: 'remove-padding',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'success') {
        this.callGetComponent.emit();
      }
    });
  }

}