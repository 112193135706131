import {Component, Input, OnInit} from '@angular/core';
import * as selectorsApp from '../../../../../store/app/app.selectors';
import {selectAppViewPort} from '../../../../../store/app/app.selectors';
import {setLanguage} from '../../../../../store/app/app.actions';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngrx/store';
import {State} from '../../../../../store';

@Component({
  selector: 'app-public-stepper',
  templateUrl: './public-stepper.component.html',
  styleUrls: ['./public-stepper.component.scss']
})
export class PublicStepperComponent implements OnInit {

  @Input() hasCampaign$;
  @Input() hasProgressProposal$;
  @Input() hasReport$;
  @Input() hashCampaignId$;
  @Input() brandLogo;
  @Input() campaign_type;
  @Input() campaignName;
  @Input() startDate;
  @Input() endDate;

  componentName = '';
  languageSelected = '';
  viewport: string;

  steps = [
    { id: 'SharedCampaignComponent', route: 'sharedCampaign', translationKey: 'campaignPlan' },
    { id: 'SharedProgressComponent', route: 'sharedProgress', translationKey: 'contentValidation' },
    { id: 'SharedReportComponent', route: 'shared', translationKey: 'campaignReport' },
  ];

  constructor(
              private activatedRoute: ActivatedRoute,
              public translate: TranslateService,
              private store: Store<State>,
              private router: Router) {
  }

  ngOnInit() {
    this.store.select(selectorsApp.selectLanguage).subscribe(response => {
      this.languageSelected = response;
    });
    this.store.select(selectAppViewPort).subscribe(result => {
      this.viewport = result;
    });
    if (this.activatedRoute.snapshot.queryParams.lang) {
      this.translate.setDefaultLang(this.activatedRoute.snapshot.queryParams.lang);
      this.translate.use(this.activatedRoute.snapshot.queryParams.lang);
      this.languageSelected = this.activatedRoute.snapshot.queryParams.lang;
    }
    // Get the name of the component for print with details the circleComponent from header
    if (typeof this.activatedRoute.snapshot.component !== 'string') {
      this.componentName = this.activatedRoute.snapshot.component.name;
    }
  }

  switchLang(lang: string) {
    this.store.dispatch(setLanguage({ language: lang }));
    this.translate.use(lang);
    this.languageSelected = lang;

    // Logica para modificar el queryparams del router URL
    // Obtener el valor actual de los parámetros de la URL.
    const queryParams = { ...this.activatedRoute.snapshot.queryParams };
    // Modificar o agregar el nuevo valor de los parámetros.
    queryParams.lang = lang; // Aquí puedes establecer el valor que desees.
    // Crear un objeto de tipo NavigationExtras y establecer los nuevos parámetros en él.
    const navigationExtras: NavigationExtras = {
      queryParams: queryParams,
      // Opcional: Si deseas reemplazar los parámetros existentes en lugar de agregarlos, utiliza queryParamsHandling: 'merge'.
      // queryParamsHandling: 'merge'
    };
    // Navegar a la misma ruta con los nuevos parámetros.
    this.router.navigate([], navigationExtras);
  }

}
