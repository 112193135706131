import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-modal-extend-campaign',
  templateUrl: './modal-extend-campaign.component.html',
  styleUrls: ['./modal-extend-campaign.component.scss']
})
export class ModalExtendCampaignComponent implements OnInit {


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

}
