import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NewCampaignComponent } from '../new-campaign/new-campaign.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-campaign-step1',
  templateUrl: './new-campaign-step1.component.html',
  styleUrls: ['./new-campaign-step1.component.scss']
})
export class NewCampaignStep1Component implements OnInit, OnDestroy {

  steps = [
    { icon: 'list_alt', label: this.translate.instant('stepper.campaignPlan') },
    { icon: 'chat', label: this.translate.instant('stepper.campaignBrief') },
    { icon: 'view_kanban', label: this.translate.instant('stepper.campaignProgress') },
    { icon: 'insert_chart', label: this.translate.instant('stepper.campaignReport') }
  ];

  flowSelectedForm: FormGroup;

  loading = false;

  private subscriptions: Subscription[] = [];
  

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    public dialogRef: MatDialogRef<NewCampaignStep1Component>,
    public snackBar: MatSnackBar,
    private _campaignsService: CampaignsService,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {
    this.flowSelectedForm = new FormGroup({
      'complete_flow': new FormControl(true),
      'management_report': new FormControl(false),
      'report_only': new FormControl(false),
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  selectFlow(flowName) {
    if (this.flowSelectedForm.controls[flowName].value === true) {
      this.flowSelectedForm.controls[flowName].setValue(false);
    } else {
      this.flowSelectedForm.controls[flowName].setValue(true);
    }
    const flowOptions = ['complete_flow', 'management_report', 'report_only'];

    flowOptions.forEach(option => {
      this.flowSelectedForm.controls[option].setValue(option === flowName);
    });
    
  }


  goToStep2() {
    if (this.data.isAncientCampaign) {
      this.updateAncientCampaignFlow();
    } else {
      let flowSelected = '';
      let flowSelectedForBack = '';
      if (this.flowSelectedForm.controls['complete_flow'].value === true) {
        flowSelected = this.translate.instant('campaigns.newCampaignModal.completeFlow');
        flowSelectedForBack = 'complete_flow';
      } else if (this.flowSelectedForm.controls['management_report'].value === true) {
        flowSelected = this.translate.instant('campaigns.newCampaignModal.managementAndReport');
        flowSelectedForBack = 'management_report';
      } else if (this.flowSelectedForm.controls['report_only'].value === true) {
        flowSelected = this.translate.instant('campaigns.newCampaignModal.report');
        flowSelectedForBack = 'report_only';
      }
      const dialogRef = this.dialog.open(NewCampaignComponent, {
        width: '625px',
        height: '625px',
        data: {
          influencers: this.data.influencers,
          flowSelected: flowSelected,
          flowSelectedForBack: flowSelectedForBack,
          createCampaign: true,
          planNameShown: this.data.planNameShown
        },
        panelClass: 'remove-padding',
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          if (res['status'] === 'created' || res.status === 'added') {
            const result_dialog = {
              status: res['status'],
              refresh: res['refresh']
            };
            this.dialogRef.close(result_dialog);
          }
        } else {
          this.dialogRef.close();
        }
      });
    }
  }


  updateAncientCampaignFlow() {
    this.loading = true;
    let flowSelected = '';
    let flowSelectedForBack = '';
    if (this.flowSelectedForm.controls['complete_flow'].value === true) {
      flowSelected = this.translate.instant('campaigns.newCampaignModal.completeFlow');
      flowSelectedForBack = 'complete_flow';
    } else if (this.flowSelectedForm.controls['management_report'].value === true) {
      flowSelected = this.translate.instant('campaigns.newCampaignModal.managementAndReport');
      flowSelectedForBack = 'management_report';
    } else if (this.flowSelectedForm.controls['report_only'].value === true) {
      flowSelected = this.translate.instant('campaigns.newCampaignModal.report');
      flowSelectedForBack = 'report_only';
    }
    const config = {
      campaign_type: flowSelectedForBack
    }
    this.subscriptions.push(
      this._campaignsService.updateCreateCampaignModal(this.data.campaign_id, config).subscribe(
        () => {
          this.loading = false;
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: this.translate.instant('campaigns.newCampaignModal.updateSuccess'), type: 'success' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'success-snackbar',
          });
          this.dialogRef.close({status: 'added'});
        },
        () => {
          this.loading = false;
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: 'error-snackbar',
          });
        }
      )
    );
  }

}