import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ATTRIBUTES_TO_UPDATE } from '../../../../../constants/attributes_to_update';
import { FormControl, FormGroup } from '@angular/forms';
import { COUNTRY_CODES } from '../../../../../constants/countries';
import { AuthenticatedUser } from '../../../../../classes/authenticated-user-class';
import { environment } from '../../../../../../environments/environment';
import { AmplifyService } from 'aws-amplify-angular';
import { MatDialog } from '@angular/material';
import { AuthenticationService } from '../../../../../store/auth/authentication.service';
import { Store } from '@ngrx/store';
import { State } from '../../../../../store';
import { Router } from '@angular/router';
import * as selectorsAuth from '../../../../../store/auth/authentication.selectors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ModalSelectBonusComponent } from '../../../../../features/private/modals/modal-select-bonus/modal-select-bonus.component';
import { ModalPlansComponent } from '../../../../../features/modal-plans/components/modal-plans/modal-plans.component';
import { getAccessSets } from '../../../../../store/auth/authentication.actions';


@Component({
  selector: 'app-edit-profile-settings',
  templateUrl: './edit-profile-settings.component.html',
  styleUrls: ['./edit-profile-settings.component.scss']
})
export class EditProfileSettingsComponent implements OnInit, OnDestroy {

  saving_changes = false;
  update_form: FormGroup;
  countries = COUNTRY_CODES;
  account_spinner = true;
  company_name: string;
  user_sub = '';
  authenticated_user: AuthenticatedUser;
  platformID = environment.platform_id;
  haveExpirationDate = false;
  expirationDate;
  planNameShown = '';


  @Input() email;

  tapSelected = 'editProfile';

  loading = false;
  loadingForm = false;
  crawlsLimits = {};
  bonusResponse = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private _amplifyService: AmplifyService,
    private dialog: MatDialog,
    private _authService: AuthenticationService,
    private store: Store<State>,
    private router: Router,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {
    this.loadingForm = true;
    this.update_form = new FormGroup({
      'custom:company_name': new FormControl(''),
      'custom:company_number': new FormControl(''),
      'address': new FormControl(''),
      'custom:country': new FormControl(''),
      'custom:city': new FormControl(''),
      'custom:state': new FormControl(''),
      'custom:zip_code': new FormControl(''),
    });
    this.getAllAttributes();
  }

  ngOnInit() {
    this.loading = true;
    this.store.select(selectorsAuth.selectAccessSets).subscribe(user => {
      this.planNameShown = user.PLAN_NAME_SHOWN;
    });
    this.getPlanExpirationData();
    this.getCrawlsLimit();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getCrawlsLimit() {
    this.loading = true;
    this.subscriptions.push(
      this._authService.getCrawlsLimit().subscribe(response => {
        this.crawlsLimits['audience_limit'] = response.body.audience_limit;
        this.crawlsLimits['consumed_audience'] = response.body.consumed_audience;
        this.bonusResponse = response.body.extra_analyze_bonus;
        if (this.crawlsLimits['consumed_audience'] > this.crawlsLimits['audience_limit']) {
          this.crawlsLimits['consumed_audience'] = this.crawlsLimits['audience_limit'];
        }
        this.cdr.detectChanges();
        this.loading = false;
      }, () => {
        this.loading = false;
      })
    );
  }

  async getAllAttributes() {
    const user = await this._amplifyService.auth().currentAuthenticatedUser({ bypassCache: true });
    this.authenticated_user = new AuthenticatedUser(user);
    this.user_sub = this.authenticated_user.getAttribute('sub');
    this.setAttributesInForm();
    this.account_spinner = false;
  }

  saveChangesProfile(): void {
    this.saving_changes = true;
    this._amplifyService.auth().currentAuthenticatedUser().then(user => {
      this.replaceEmptyAttributes(this.update_form.controls);
    });
  }

  openSnackBar(message, action) {
    this.snackBar.open(message, action, {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }


  setAttributesInForm(): void {
    this.replaceNAAttributes(this.update_form.controls);
  }


  replaceEmptyAttributes(update_form_controls) {
    // tslint:disable-next-line:forin
    for (const key in ATTRIBUTES_TO_UPDATE) {
      if (update_form_controls[key].value === '') {
        update_form_controls[key].value = 'n/a';
      }
      ATTRIBUTES_TO_UPDATE[key] = update_form_controls[key].value;
    }
  }

  replaceNAAttributes(update_form_controls) {
    for (const key in update_form_controls) {
      if (update_form_controls.hasOwnProperty(key)) {
        if (this.authenticated_user.getAttribute(key) === 'n/a') {
          this.update_form.controls[key].setValue('');
        } else {
          this.update_form.controls[key].setValue(this.authenticated_user.getAttribute(key));
        }
      }
    }
    this.loadingForm = false;
  }


  cancelPlan() {
    this.router.navigate(['/app/cancelPlan']);
  }

  getPlanExpirationData() {
    this.account_spinner = true;
    this._authService.getPlanExpirationData().subscribe(res => {
      this.account_spinner = false;
      if (res['state'] === 'deleted') {
        this.haveExpirationDate = true;
        this.expirationDate = res['cancellation_effective_date'];
      }
    }, () => {
      this.account_spinner = false;
    });
  }

  openGetBonusTokens() {
    if (this.planNameShown === 'FREE') {
      if (environment.platform_id === '11posts') {
        return this.dialog
          .open(ModalPlansComponent, {
            width: '1400px',
            // height: '1000px',
            height: '100%',
            panelClass: 'container-modal-plans',
            autoFocus: false,
            data: {
            }
          })
          .afterClosed().subscribe(result => {
            this.getCrawlsLimit();
          });
      }
    } else {
      const dialogRef = this.dialog.open(ModalSelectBonusComponent, {
        width: '700px',
        height: '640px',
        autoFocus: false,
        data: {
          email: this.email,
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.loading = true;
        setTimeout(() => { this.getCrawlsLimit(); }, 4700);
      });
    }

  }


  routeChangePassword() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/app/changePassword']));
    window.open(url, '_blank');
  }

  showRedLimitNotice() {
    const percentageUsed = (this.crawlsLimits['consumed_audience'] / this.crawlsLimits['audience_limit']) * 100;
    if (percentageUsed >= 80) {
      return true;
    } else {
      return false;
    }
  }
}
