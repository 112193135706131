import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalPlansService } from '../../store/modal-plans.service';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { CustomSnackbarComponent } from 'src/app/features/shared/components/custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-modal-personalized-plan',
  templateUrl: './modal-personalized-plan.component.html',
  styleUrls: ['./modal-personalized-plan.component.scss']
})
export class ModalPersonalizedPlanComponent implements OnInit, OnDestroy {

  requestConfirmed = false;
  loading = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private modalPlansService: ModalPlansService,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  confirmRequest() {
    this.loading = true;
    this.modalPlansService.customPlanNotice().subscribe(() => {
      this.snackBar.openFromComponent(CustomSnackbarComponent, {
        data: { message: this.translate.instant('modalPersonalizedPlan.requestSentSuccessfully'), type: 'success' },
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: 'success-snackbar',
      });
      this.requestConfirmed = true;
      this.loading = false;

    }, () => {
      this.snackBar.openFromComponent(CustomSnackbarComponent, {
        data: { message: this.translate.instant('shareCampaignPlanModal.oopsError'), type: 'error' },
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: 'error-snackbar',
      });
      this.loading = false;

    });
  }

}
